
import { Component, Inject, Prop, Ref, Vue } from "vue-property-decorator";
import _ from "lodash";
import { PROVIDER, SERVICES as S } from "@/config/literals";
import { DependencyContainer } from "tsyringe";

import CreateUi from "@/components/Core/Create/CreateUI.vue";

import { IEquipmentBrandService } from "@/services";

@Component({
  components: {
    CreateUi,
  },
})
export default class EquipmentBrandCreate extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  forbiddenNames!: string[];

  @Ref("form")
  form!: any;

  get equipmentBrandService() {
    return this.container.resolve<IEquipmentBrandService>(
      S.EQUIPMENT_BRAND_SERVICE
    );
  }

  loading: boolean = false;
  name: string | null = null;

  async create() {
    try {
      this.loading = true;
      const brandCreated = await this.equipmentBrandService.create({
        name: this.name!,
      });
      this.$emit("create", brandCreated);
    } finally {
      this.loading = false;
    }
  }

  doesBrandAlreadyExists(brandName: string) {
    const index = _.findIndex(
      this.forbiddenNames,
      (b) => b.toUpperCase() == brandName.toUpperCase()
    );
    return index != -1;
  }
}
